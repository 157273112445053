import React from 'react';
import { getStatus } from '../utils';
import Loading from './Loading';


function Submission({task_id}) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [result, setResult] = React.useState("")
  React.useEffect(() => {
    const interval_id = setInterval(() => {
      getStatus(task_id).then(res=> {
        console.log(res)
        if (res["state"]==="PROGRESS") {
          setIsLoading(true);
          setResult(`Progress: ${res["progress"]}`)
        }
        if (res["state"]==="SUCCESS") {
          setIsLoading(false);
          clearInterval(interval_id)
          setResult(res["meta"])
        }
      })
      return () => clearInterval(interval_id)
    }, 1000)
  }, [task_id])
  return (
    <div className="container">
      { isLoading ? <Loading /> :
        <div className="row justify-content-center">
          <div className="col-auto">
            <pre>
              <code className="language-javascript">
                {result}
              </code>
            </pre>
          </div>
        </div>
      }
    </div>
  );
}

export default Submission;