import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/home";
import Problem from "./pages/problem";
import FutureMe from "./pages/futureme";

function App() {
  const paths = [
    { path: "/", name: "Home", element: <Home /> },
    { path: "/problem", name: "Problem", element: <Problem /> },
    { path: "/contest", name: "Contest", element: <div>C</div> },
  ];

  return (
    <Router>
      <Navbar name="Welcome!" paths={paths} />
      <Routes>
        <Route path={"/futureme"} element={<FutureMe />} key={-1} />
        {paths.map((e, i) => (
          <Route path={e.path} element={e.element} key={i} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
