import React from 'react';

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Analyzing...</span>
      </div>
    </div>
  );
}

export default Loading;
