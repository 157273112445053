import React from 'react';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import VersionDisplay from './VersionDisplay';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { getGoogleUser, UserContext } from '../utils';

const ENABLE_GOOGLE_AUTH = false;

function Navbar({name, paths}) {
  const { user, setUser } = useContext(UserContext);

  const login = useGoogleLogin({
    onSuccess: (res) => getGoogleUser(res.access_token).then(user => setUser(user.data)),
    onError: (error) => console.log('Login Failed:', error)
  });

  const logout = () => {
    googleLogout();
    setUser(null);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/" style={{ position: 'relative' }}>
        {name}
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          {paths.map((e, i)=> (
            <li className={"nav-item" + (i===0?" active":"")} key={i}>
              <Link className="nav-link" to={e.path}>{e.name}</Link>
            </li>
          ))}
        </ul>
      </div>
      {user===null?
      <button onClick={()=>ENABLE_GOOGLE_AUTH&&login()}>Sign in with Google 🚀 </button>
      :
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
        <button onClick={()=>ENABLE_GOOGLE_AUTH&&logout()}>Sign Out</button>
        <img style={{height: '30px', marginRight: '5px', marginLeft: '5px'}} src={user.picture} alt="user image" />
        <div>{user.name}</div>
      </div>
      }
      <VersionDisplay />
    </nav>
  );
}

export default Navbar;
