import React from 'react';
import { postData } from '../utils';


function ButtonPost({name, url, data={}, onClick=e=>{}}) {
  const handleClick = async () => {
    const response = await postData(url, data);
    onClick(response)
  };

  return (
    <div className="container my-4">
      <div className="row justify-content-center">
        <div className="col-auto">
          <button className="btn btn-primary" onClick={handleClick}>{name}</button>
        </div>
      </div>
    </div>
  );
}

export default ButtonPost;