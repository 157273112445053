import React from 'react';

const version = "0.2.0";

const VersionDisplay = () => {
  return (
    <div className="version-display">
      <span>v{version}</span>
    </div>
  );
};

export default VersionDisplay;