import { createContext, useState } from 'react';
import axios from 'axios';

export function getCsrfToken() {
  let csrfToken = null;
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.startsWith('csrftoken=')) {
        csrfToken = trimmedCookie.substring('csrftoken='.length);
        break;
      }
    }
  }
  return csrfToken;
}


export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const csrfToken = getCsrfToken()
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getVersion() {
  // Default options are marked with *
  const csrfToken = getCsrfToken()
  const url = "api/version"
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function sendToFuture(text) {
  // Default options are marked with *
  const csrfToken = getCsrfToken()
  const url = "futureme/send"
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify({'text': text})
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getStatus(task_id) {
  // Default options are marked with *
  const csrfToken = getCsrfToken()
  const response = await fetch("api/task_status", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify({ "task_id": task_id })
  });
  return response.json();
}

export async function getGoogleUser(access_token) {
  return axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      Accept: 'application/json'
    }
  })
}

export const UserContext = createContext(null);
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};