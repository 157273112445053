import { useState } from 'react';
import styled from 'styled-components';
import SendEmailIcon from '../components/SendEmailIcon';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { sendToFuture } from '../utils';

const StyledMessage = styled.div`
  position: absolute;
  right:0;
  top:0;
  height: 30px;
`

const StyledBody = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: gray;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const StyledContainer = styled.div`
  position: relative;
  width: 90%;
  max-width: 50rem;
  height: 37.5rem;
  font-family: system-ui;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 90vh;
  }
`
const StyledHeader = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  font-size: x-large;
  padding-left: 10px;
  color: #dadfe3;
  font-style: italic; // Make the font italic
`

const StyledTextarea = styled.textarea`
  // Use a media query for smaller screen sizes (like mobile devices)
  resize: none;
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: system-ui;
  &:focus {
    outline: none;
  }
  width: 100%;
  height: calc(100% - 100px);
  box-shadow: 0px 0px 4px 2px #cfd5de;
  border: none;
`

const StyledFooter = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  font-size: x-large;
  color: #dadfe3;
  display: flex;
  justify-content: end;
  align-items: center;
`

const StyledButton = styled.div`
  margin-right: 6px;
  width: 80px;
  height: 40px;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #bcc7d6 solid 1px;
  border-radius: 40px;
  box-shadow: 0px 0px 4px 2px #cfd5de;
  &:hover {
    cursor: pointer;
    background-color: #ced7e3;
  }
  &:focus {
    background-color: #cfd7e2;
  }
`
const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
`
const StyledSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
  color: #8f8787;
`

function FutureMe() {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [uploading, setUploading] = useState(false)

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const currentDate = new Date();
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const dateString = currentDate.toLocaleDateString('en-US', options).replaceAll('/', '-');

  const handleSend = () => {
    if (uploading) return;
    setUploading(true);
    sendToFuture(code).then(
      res => {
        console.log(res)
        setCode("")
        setMessage("SUCCESS!!!!!!")
        setError("")
        setUploading(false)
      }
    ).catch(error=>setError(String(error)))
  }

  return (
    <StyledBody>
      <StyledContainer>
        <StyledMessage>
        {message!==''&&
          <Alert variant="success">
            {message}
          </Alert>
        }
        {error!==''&&
          <Alert variant="danger">
            {error}
          </Alert>
        }
        </StyledMessage>
        <StyledHeader>
          {dateString}
        </StyledHeader>
        <StyledTextarea
          value={code}
          onChange={handleCodeChange}
        />
        <StyledFooter>
          <StyledButton tabIndex="0" onClick={handleSend}>
            {uploading ? 
              <StyledSpinner animation="border" role="status"></StyledSpinner>
              :
              <StyledIcon>
                <SendEmailIcon />
              </StyledIcon> 
            }
          </StyledButton>
        </StyledFooter>
      </StyledContainer>
    </StyledBody>
  )
}
export default FutureMe;