// CodeBlock.js
import React from 'react';

const CodeBlock = ({ code, setCode }) => {
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const fixedSizeStyle = {
    width: '800px',
    height: '600px',
    fontFamily: 'monospace'
  };

  return (
    <div>
      <textarea
        className="form-control"
        style={fixedSizeStyle}
        value={code}
        onChange={handleCodeChange}
        placeholder="Enter your code here..."
      />
    </div>
  );
};

export default CodeBlock;
