export default function() {
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 100.000000 100.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M768 845 c-3 -3 -158 -9 -344 -13 l-339 -7 -6 -30 c-12 -54 -10 -603
2 -610 5 -4 64 1 131 10 66 10 197 25 291 34 139 13 172 19 172 31 0 12 -15
15 -85 13 -75 -2 -240 -18 -395 -39 l-50 -7 98 93 c54 51 95 95 93 98 -3 2
-54 -39 -113 -92 -79 -71 -109 -93 -111 -81 -2 9 6 28 19 41 22 24 14 35 -9
12 -9 -9 -12 -8 -12 8 0 11 6 26 12 32 10 10 10 15 -2 22 -12 8 -13 13 -2 30
7 12 8 20 2 20 -6 0 -10 66 -10 180 0 99 4 180 8 180 5 0 20 -13 35 -28 25
-26 26 -29 11 -51 -22 -30 -7 -42 15 -12 22 29 35 14 26 -29 -7 -34 10 -42 18
-9 9 33 29 12 22 -22 -7 -33 10 -40 18 -8 3 13 10 18 17 14 7 -4 9 -18 6 -31
-4 -14 -2 -24 4 -24 5 0 10 5 10 10 0 6 7 10 16 10 12 0 15 -6 10 -25 -6 -24
9 -36 16 -13 9 26 29 -5 23 -35 l-6 -32 18 23 c14 17 21 20 30 11 8 -8 9 -18
2 -31 -7 -12 -6 -18 1 -18 6 0 13 6 16 14 8 21 37 7 31 -14 -4 -15 1 -17 37
-13 36 5 53 17 134 97 51 50 119 122 150 159 46 57 57 66 62 50 4 -10 15 -99
25 -198 17 -179 24 -210 46 -188 11 11 3 112 -29 408 l-7 60 -50 3 c-28 2 -53
0 -57 -3z m15 -97 c-77 -99 -248 -268 -271 -268 -31 0 -358 264 -370 301 -2 5
77 10 200 10 112 1 253 5 313 9 61 3 125 7 143 8 l33 2 -48 -62z"/>
<path d="M447 741 c-48 -3 -87 -10 -87 -16 1 -18 54 -26 130 -20 96 8 120 14
120 31 0 15 -1 15 -163 5z"/>
<path d="M775 600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M710 540 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"/>
<path d="M765 490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M652 451 c3 -9 175 -141 183 -141 20 0 -7 27 -84 84 -85 63 -107 76
-99 57z"/>
<path d="M875 340 c-3 -5 -1 -18 5 -29 5 -11 10 -22 10 -25 0 -3 -42 -7 -92
-8 -75 -2 -93 -6 -93 -18 0 -12 18 -15 98 -15 83 0 96 -2 91 -15 -17 -40 -16
-50 2 -50 16 0 57 48 73 83 4 9 -68 87 -80 87 -4 0 -11 -4 -14 -10z"/>
</g>
</svg>
  )
}