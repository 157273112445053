import React, { useState } from 'react';
import CodeBlock from "../components/CodeBlock";
import ButtonPost from '../components/ButtonPost';
import Submission from '../components/Submission';


function Problem() {
  const [code, setCode] = useState('');
  const [ID, setID] = useState(null)
  const [status, setStatus] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false)

  const problemStyle = {
    display: 'flex',
    justifyContent: 'center', // Centers the child horizontally
    alignItems: 'center', // Centers the child vertically (optional)
    flexDirection: 'column', // Stacks children vertically
    paddingTop: '5vh',
  };

  return (
    <div style={problemStyle}>
      <CodeBlock code={code} setCode={setCode} />
      <ButtonPost name='Submit Code' url='api/runcode' data={{'code': code}} onClick={res => {
        console.log(res)
        setID(res.task_id)
        setIsAnalyzing(true)
      }}/>
      <ButtonPost
      key={2}
      name="Check"
      url='api/task_status'
      data={{task_id: ID}}
      onClick={(res => {
        console.log(res)
      })}
      />
      { isAnalyzing && <Submission task_id={ID}/> }
      {status.length>0&&<h4>{status}</h4>}
    </div>
  );
}

export default Problem;
